<template>
  <div class="">
    <nav-bar></nav-bar>
    <div class="home-bannner pb-10 min-h-[576px] bg-center bg-cover">
      <section class="container mx-auto px-5 lg:px-24 banner flex flex-col md:flex-row ">
        <div class="banner-container basis-2/2 md:basis=1/3">
          <div class="bn-text mt-10">
            <div class="bn-txt-holder">
              <h1 class="text-[32px] md:text-[48px]">
                Driving change through <br/>
                AI & Data solutions
              </h1>
              <p>
                Join a collaborative AI research hub and innovation ecosystem
                focusing on developing scalalble Artificial Intelligence solutions
                
              </p>
              <div class="bn-btns">
                <a href="https://calendly.com/intelliverse-ai/intelliverse-chat">
                  <button
                    class="find-btn flex items-center gap-2 justify-center"
                  >
                    Let's Chat
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                      />
                    </svg></button
                ></a>
              </div>
            </div>
          </div>
          <div class=""></div>
        </div>
      </section>
    </div>
    <main class="container mx-auto px-5 lg:px-24">
      
      <section class="community-talent">
        <div class="community-talent-contain container">
          <div class="ctc-content">
            <div class="ctc-card">
              <h3 class="">
                More training Data for robust Machine learning models
              </h3>
            </div>
            <hr
              class="w-full h-0.5 md:h-40 md:w-0 border-solid border-white border-[1px]"
            />

            <div class="ctc-card">
              <h3>Reduced costs and risks of AI Adoption</h3>
            </div>
            <hr
              class="w-full h-0.5 md:h-40 md:w-0 border-solid border-white border-[1px]"
            />
            <div class="ctc-card">
              <h3>Faster development and deployment of AI applications.</h3>
            </div>
            <hr
              class="w-full h-0.5 md:h-40 md:w-0 border-solid border-white border-[1px]"
            />
            <div class="ctc-card">
              <h3>Enhanced data security and privacy.</h3>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div
          class="flex flex-col-reverse md:flex-row justify-between community gap-10"
        >
          <div class="grid grid-cols-1 md:grid-cols-2 gap-10 md:basis-1/2">
            <div class="card rounded bg-gray-100 hover:bg-gray-800 hover:text-white">
              <div class="flex items-center mb-2">
                <img
                  src="/images/Hackathon/management-team.svg"
                  alt="Hackathon"
                />
                <h4>Joint Research Projects</h4>
              </div>
              <p>
                Collaborate with our team on cutting-edge research projects in
                various AI domains.
              </p>
            </div>

            <div class="card rounded bg-gray-100 hover:bg-gray-800 hover:text-white">
              <div class="flex items-center mb-2">
                <img
                  src="/images/Hackathon/team-creation.svg"
                  alt="Hackathon"
                />
                <h4>Proof-of-Concept Development:</h4>
              </div>
              <p>
                Validate the feasibility of your AI ideas through rapid
                prototyping and testing.
              </p>
            </div>
            <div class="card rounded bg-gray-100 hover:bg-gray-800 hover:text-white">
              <div class="flex items-center mb-2">
                <img
                  src="/images/Hackathon/analysis-support.svg"
                  alt="Hackathon"
                />
                <h4>Data repository</h4>
              </div>
              <p>
                Find Comprehensive data sets from our community to work with on
                your AI research projects
              </p>
            </div>
            <div class="card rounded bg-gray-100 hover:bg-gray-800 hover:text-white">
              <div class="flex items-center mb-2">
                <img
                  src="/images/Hackathon/design-support.svg"
                  alt="Hackathon"
                />
                <h4>ML Model Development & Deployment</h4>
              </div>
              <p>
                We work closely with you to design and develop AI solutions that
                meet your specific requirements.
              </p>
            </div>
          </div>
          <div class="text basis-1/2">
           
            <h3>Plug Into Intelliverse</h3>
            <div class="w-full lg:h-[450px] ">
              <img src="/images/n.png" class="rounded h-full "/>
            </div>
           
            
          </div>
        </div>
      </section>
  
      <section class="extra-info">
        <div class="extra-info-container container">
          <div class="text">
            <div class="text-hold">
              <h3>
                Let's Build the future of Artificial Intelligence together
              </h3>
              <a
                href="https://calendly.com/intelliverse-ai/intelliverse-chat"
                class="rounded-lg"
                >Join The Hub</a
              >
            </div>
          </div>
        </div>
      </section>
      <section class="innovation-sect" id="partners">
        <div class="innovation-sect-container container mx-auto">
          <div class="innovate-head">
            <h1 class="upppercase">
              Bring Your
              <span class="text-[#E58E04]">Artificial Intelligence Ideas</span>
              To Life Faster
            </h1>
            <p>
              Through strategic partnerships, Intelliverse aims to transform challenges into
              opportunities, ensuring that the we can harness the
              true power of Artificial Intellignce in solving the most pressing
              challenges. 
            </p>
          </div>
          <div class="innovate-content grid grid-cols-1 md:grid-cols-3 gap-6">
            <div class="innovate-card">
              <img
                class="image-one"
                src="/images/Hackathon/hackathons-icon.svg"
                alt="Hackerthon"
              />
              <h2>Active Community</h2>
              <p>
                Engaging a vibrant community of over 1500+ AI researchers and engineers  to develop models for your next AI
                project
              </p>
              <br />
              <button>
                <a href="https://calendly.com/intelliverse-ai/intelliverse-chat"
                  >Join Us Today</a
                >
              </button>
            </div>
            <div class="innovate-card">
              <img
                class="image-one"
                src="/images/Hackathon/hiring-challenges-icon.svg"
                alt="Hackerthon"
              />
              <h2>ENTERPRISE AI</h2>
              <p>
                We develop end-to-end enterprise level AI solutions from planning,development to deployment and maintenaince
              </p>
              <br />
              <button>
                <a href="https://calendly.com/intelliverse-ai/intelliverse-chat"
                  >Request a demo</a
                >
              </button>
            </div>
            <div class="innovate-card">
              <img
                class="image-one"
                src="/images/Hackathon/evaluation.svg"
                alt="Hackerthon"
              />
              <h2>PARTNERSHIPS</h2>
              <p>
                Universities,NGOs,and Governments,Private Enterprises can
                contribute datasets to help foster AI research and jointly build
                better AI models and solutions
              </p>

              <br />
              <button>
                <a href="https://calendly.com/intelliverse-ai/intelliverse-chat"
                  >Speak to Our Team</a
                >
              </button>
            </div>
          </div>
        </div>
      </section>
    
    
     
    </main>
    <section class="newsletter">
      <div
        class="newsletter-contain container flex flex-col md:flex-row items-center justify-evenly"
      >
        <div class="text">
          <div class="img">
            <i class="fa-brands fa-telegram"></i>
          </div>
          <div class="news-txt">
            <span
              >WANT TO GET LATEST AI RESEARCH UPDATES! SIGN UP FOR FREE.</span
            >
            <h1>SUBSCRIBE NEWSLETTER !</h1>
          </div>
        </div>
        <div class="form">
          <form class="newsletter" @submit.prevent="handleSubmit">
            <input
              type="text"
              placeholder="Enter Email Address"
              v-model="email"
            />
            <button type="submit">
              {{ loading ? "loading" : "Subscribe" }}
            </button>
          </form>
        </div>
      </div>
    </section>
    <footer-component></footer-component>
  </div>
</template>

<script>
import NavBar from "@/components/utils/NavBar.vue";
import FooterComponent from "@/components/utils/FooterComponent.vue";
import { mapState } from "vuex";
 import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  data() {
    return {
      email: "",
      settings:{
  "centerMode": true,
  "centerPadding": "40px",
  "focusOnSelect": true,
  "infinite": true,
  "slidesToShow": 3,
  "speed": 3000,
  "dots":true,
  "autoPlay":true,

},
slides: [
        { img: '/images/about.png', alt: 'Image 1' },
        
        { img: '/images/events-banner.png', alt: 'Image 1' },
        { img: '/images/about.png', alt: 'Image 1' },
        { img: '/images/events-banner.png', alt: 'Image 1' },
        { img: '/images/events-banner.png', alt: 'Image 1' },
       
      ],
      slickOptions: {
        slidesToShow: 3, // Show three slides at a time
        autoplay: true,  // Enable autoplay
        autoplaySpeed: 2000, // Autoplay speed in milliseconds (e.g., 2000ms = 2 seconds)
        dots: true, // Enable dots navigation if needed
        arrows: true // Enable next/prev arrows if needed
      }
    };
  },
     slides: [
        { img: 'path/to/image1.jpg', alt: 'Image 1' },
        { img: 'path/to/image2.jpg', alt: 'Image 2' },
        { img: 'path/to/image3.jpg', alt: 'Image 3' }
      ],
  components: {
    NavBar,
    FooterComponent
  },
  computed: {
    ...mapState(["loading"]),
  },
  methods: {
    handleSubmit() {
      this.$store.dispatch("sendMail", {
        name: "Newsletter Subscriptions",
        email: "ochiengwarren10@gmail.com,info@intelliverseai.com",
        subject: "Newsletter Subscriptions",
        content: `${this.email},`,
        link: `info@intelliverseai.com`,
      });
    },
  },
};
</script>

<style>
.slide{
margin-right: 20px !important;
}
</style>
