<template>
      <footer>
            <div class="ft-holder container">
                <div class="top-ft">
                    <div class="ft-column social">
                       <img src="/images/logo-white.png" alt="" class="w-40">
                        <p class="-mt-10">Join a collaborative AI research hub and innovation ecosystem focusing on developing scalalble Artificial Intelligence solutions</p>
                        <div class="social-icons">
                            <a href="https://www.linkedin.com/company/intelliverse-ai-lab"><i class="fab fa-linkedin"></i></a>
                            <a href="https://twitter.com/Intelliverse_AI"><i class="fab fa-twitter"></i></a>
                            <a href="https://www.linkedin.com/company/intelliverse-ai-lab"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="ft-column footer-link">
                        <h2>Product</h2>
                        <ul>
                            <li><a href="/login">Challenges</a></li>
                            <li><a href="/login">Research Papers</a></li>
                            <li><a href="/login">Data Models</a></li>
                            <li><a href="/login">Forums</a></li>
                           
                        </ul>
                    </div>
                    <div class="ft-column footer-link">
                        <h2>Documentation</h2>
                        <ul>
                            <li><a href="/login">Public API</a></li>
                            <li><a href="/login">Methodology</a></li>
                            <li><a href="/login">Public Participation</a></li>
                            <li><a href="/login">Notebooks</a></li>
                            <li><a href="/login">Launch Weekly</a></li>
                            
                        </ul>
                    </div>
                    <div class="ft-column footer-link">
                        <h2>Company</h2>
                        <ul>
                            <li><a href="/login">Our Team</a></li>
                            <li><a href="/login">Blog</a></li>
                            <li><a href="/login">Contact Us</a></li>
                            <li><a href="/login">Host a Challenge</a></li>
                        </ul>
                    </div>
                </div>
                <div class="bottom-ft">
                    <div class="copyright">
                        <p>© Intelliverse {{ new Date().getFullYear() }}. All rights reserved.</p>
                    </div>
                    <div class="ft-legal-links">
                        <a href="#">Privacy Policy</a>
                        <span>.</span>
                        <a href="#">Terms of Service</a>
                    </div>
                </div>
            </div>
        </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>